<template>
  <div>
    <input
      type="file"
      accept="image/jpeg"
      @change="detectFiles($event.target.files)"
    />
    <div class="progress-bar" :style="{ width: progressUpload + '%' }">
      {{ progressUpload }}%
    </div>
  </div>
</template>
<script>
import { db, storage } from "@/db";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
export default {
  data() {
    return {
      progressUpload: 0,
      file: File,
      uploadTask: "",
    };
  },
  methods: {
    detectFiles(fileList) {
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.upload(fileList[x]);
      });
    },
    upload(file) {
      //   this.uploadTask = storage.ref("imagenes").put(file);
      const storageRef = ref(storage, file.name);
      this.uploadTask = uploadBytesResumable(storageRef, file);
    },
  },
  watch: {
    uploadTask: function () {
      this.uploadTask // Listen for state changes, errors, and completion of the upload.
        .on(
          "state_changed",
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(this.uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
            });
          }
        );
    },
  },
};
</script>

<style>
.progress-bar {
  margin: 10px 0;
}
</style>
